import { graphql } from "gatsby";
import React from "react";
import CabinList from "../components/CabinList";
import Layout from "../components/Layout";
import i18n, { Lang } from "../i18n";
import { Cabin } from "../types";
import { humanReadableColorToKey } from "../utils/color-mapper";

export const query = graphql`
  query FrontpageQuery($uid: String!, $lang: String!) {
    prismic {
      frontpage(uid: $uid, lang: $lang) {
        name
        slogan
        highlightcolor
        heroimage
        heroimageSharp {
          id
          picture: childImageSharp {
            main: fixed(width: 2048) {
              src
            }
          }
        }
      }
      allCabins(lang: $lang) {
        edges {
          node {
            _meta {
              id
              lang
              uid
            }
            name
            shortdescritpion
            startingpriceperweek
            squaremeters
            personsmin
            personsmax
            pictures {
              picture
              pictureSharp {
                id
                picture: childImageSharp {
                  main: fluid(
                    maxWidth: 400
                    maxHeight: 270
                    cropFocus: CENTER
                  ) {
                    src
                    aspectRatio
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type FrontpageProps = {
  data: any;
  pageContext: {
    lang: Lang;
  };
};

const Frontpage = (props: FrontpageProps) => {
  const frontpage = props.data.prismic.frontpage;

  if (!frontpage) return null;

  const cabins: Cabin[] = props.data.prismic.allCabins.edges
    .map((edge: any) => edge.node)
    .map((node: any) => ({
      uid: node._meta.uid,
      name: node.name[0].text,
      shortDescription: node.shortdescritpion
        ? node.shortdescritpion[0].text
        : "",
      picture:
        node.pictures && node.pictures[0].pictureSharp
          ? node.pictures[0].pictureSharp.picture.main
          : null,
      price: node.startingpriceperweek,
      area: node.squaremeters,
      minPerson: node.personsmin,
      maxPerson: node.personsmax,
    }))
    .filter((cabin: Cabin) => cabin.picture);

  return (
    <Layout lang={props.pageContext.lang} isFrontPage={true}>
      <section
        className="bg-cover bg-center"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${frontpage.heroimageSharp.picture.main.src})`,
        }}
      >
        <div className="relative container mx-auto px-4 top-push-frontpage">
          <div className="mb-16">
            {frontpage.name[0].text
              .split(" ")
              .map((part: string, index: number) => (
                <h1
                  key={`title-${index}`}
                  className={`font-extrabold text-${humanReadableColorToKey(
                    frontpage.highlightcolor
                  )} text-6xl my-0 leading-none font-theme text-shadow-white text-center lg:text-left`}
                >
                  {part}
                </h1>
              ))}
          </div>
          <h1 className="text-white text-shadow text-double-extra-large lg:text-triple-extra-large leading-none text-center lg:text-left">
            {frontpage.slogan[0].text}
          </h1>
        </div>
      </section>

      <div className="container mx-auto px-4 relative top-pull-frontpage">
        <h3 className="text-white">
          {i18n[props.pageContext.lang].frontPage.rentACabin}
        </h3>
        <CabinList cabins={cabins} lang={props.pageContext.lang} />
      </div>
    </Layout>
  );
};

export default Frontpage;
